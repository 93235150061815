import SettingTypes from '@/utils/setting-types'

export default {
  name: 'GoodsLabel',
  label: {
    title: '商品标记列表',
    icon: require('@/assets/icon-component-GoodsLabel.png'),
    limit: 20
  },
  sort: 11,
  data: {
    // 标记内容
    content: '首页商品',
    // 显示总共条数
    page_size: '10',
    // 排序
    sort:''
  },
  settings: {
    content: { 
      type: SettingTypes.input,
      label: '标记类型',
      props: {
        placeholder: '请填写标记类型如：金世汇'
      }
    },
    page_size: {
      type: SettingTypes.select,
      label: '最多显示数量',
      show: true,
      options: [
        { label: '10条', value: '10' },
        { label: '20条', value: '20' },
        { label: '30条', value: '30' },
        { label: '40条', value: '40' },
        { label: '50条', value: '50' }
      ]
    },
    sort: {
      type: SettingTypes.select,
      label: '最多显示数量',
      show: true,
      options: [
        { label: '默认排序', value: '' },
        { label: '价格最低', value: 'price_asc' },
        { label: '价格最高', value: 'price_desc' }
      ]
    }
  }
}
