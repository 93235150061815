<template>
  <div class="goods-label">
    <p class="goods-label__title">商品标记列表</p>
    <p class="goods-label__subtitle">
      一般放在页面底部，此模块无须预览，提供占位即可。
    </p>
  </div>

  </template>
  
  <script>
  import 'vant/lib/notice-bar/style'
  
  export default {
    name: 'GoodsLabelPreview',
    data() {
      return {
        default_notice: '请填写商品标记中文名称如：金世汇',
        goods_image: require('@/assets/placeholder-image.png'),
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .goods-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    margin: 30px auto;
    background-color: #fff;
    overflow: hidden;
    &__title {
      font-size: 15px;
      text-align: center;
    }
    &__subtitle {
      font-size: 12px;
      color: #999999;
      margin-top: 10px;
      text-align: center;
    }
  }
  </style>
  