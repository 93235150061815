import SettingTypes from '@/utils/setting-types'
import siteType from '@/utils/setting-site'
export default {
  name: 'video-module',
  label: {
    title: '视频',
    icon: require('@/assets/icon-component-label_Video.png'),
    limit: 50
  },
  sort: 12,
  data: {
    // 视频链接
    video: {
      src: '',
      cover: ''
    },
    // 封面类型
    cover_type: 'original',
    // 视频封面
    video_cover: '',
    // 在非H5端显示视频模块
    not_h5_show: false,
    // 审核开关
    auditState: false,
    // 分站信息
    child_site: '',
  },
  settings: {
    not_h5_show: {
      type: SettingTypes.boolean,
      label: '在非H5端显示'
    }
  },
  auditState: {
    label: '小程序审核',
    type: SettingTypes.radio_button,
    options: [
      { label: '开启', value: true },
      { label: '关闭', value: false }
    ]
  },
  child_site: {
    type: SettingTypes.select,
    label: '分站信息',
    show: true,
    options: siteType
  }
}
